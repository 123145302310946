#ProfileMain {
  padding: 30px 0px;
}

#ProfileKisim2 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 42px;

}
#ProfileKisim2-Profile {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.ProfilYaziBoyutu {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 28px;
  padding-bottom: 20px;
}

#ProfileKisim2-1{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    }

.ProfileHakkinda{
    display: flex;
    padding-bottom: 15px;
}
.ProfileHakkinda-1{
    width: 155px;
}

.ProfileHakkinda-2{
    width: 261px;
    display: flex;
    flex-wrap: wrap;
}
#ProfileKisim2-About {
  display: flex;
  flex-direction: column;
  width: 576px;
  }

  #ProfileKisim2-About p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
  }
  

  @media(max-width: 1100px) {
    #ProfileKisim2-About {
      width: 50%;
      }
  
  }
  @media(max-width: 950px) {

    #ProfileKisim2-About {
      width: 100%;
      }
      .ProfileHakkinda-1{
        width: 150px;
    }
    
    .ProfileHakkinda-2{
        width: 70%;
    }
  }


