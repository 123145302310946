#NavMenü1 {
  margin-top: 30px;
  display: flex;
  text-align: center;
  height: 62px;
}
#KareDis {
  width: 63.68px;
  height: 62px;
  position: absolute;
  border-radius: 50%;
  display: flex;
}
.light-mode #KareDis {
  background-color: #eeebff;
}
.dark-mode #KareDis {
  background: #4731d3;
}

#Kareic {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#KareYazi {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  transform: rotate(25deg);
}
.light-mode #KareYazi {
  color: #7b61ff;
}
.dark-mode #KareYazi {
  color: #8f88ff;
}

#NavMenü2 {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 0 1rem;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
}
#NavMenü2ic {
  display: flex;
  justify-content: space-between;
  text-align: center;
  flex-wrap: wrap;

  width: 40%;
}

.buttonNav {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px 32px;
  color: #6b7280;
}


.buttonNavCerceve {
  padding: 12px 32px;
  border-radius: 6px;
  gap: 10px;
}
.light-mode .buttonNavCerceve {
  border: 1px solid #3730a3;
  background: #ffffff;
  color: #3730a3;
}
.dark-mode .buttonNavCerceve {
  background: #ffffff;
  border: 1px solid #3730a3;
  color: #3730a3;
}

@media (max-width: 1100px) {
  #NavMenü2ic {
    width: 45%;
  }
  .buttonNav {
    padding: 9px 24px;
  }
  .buttonNavCerceve {
    padding: 9px 24px;
    gap: 10px;
  }
}

@media (max-width: 950px) {
  #NavMenü2ic {
    width: 55%;
  }
}

@media (max-width: 768px) {
  #NavMenü2ic {
    width: 65%;
  }
  .buttonNav {
    padding: 9px 24px;
  }
  .buttonNavCerceve {
    padding: 9px 24px;
    gap: 10px;
  }
}
@media (max-width: 600px) {
  #NavMenü2ic {
    width: 80%;
  }
}

@media (max-width: 500px) {
  #NavMenü2ic {
    width: 85%;
  }
  .buttonNav {
    padding: 9px 24px;
  }
  .buttonNavCerceve {
    padding: 9px 24px;
    gap: 10px;
  }
}

@media (max-width: 450px) {
  .buttonNav {
    padding: 6px 12px;
  }
  .buttonNavCerceve {
    padding: 6px 12px;
  }
}

@media (max-width: 425px) {
  #NavMenü2ic {
    width: 85%;
    justify-content: space-evenly;
  }
  .buttonNav {
    padding: 6px 16px;
  }
  .buttonNavCerceve {
    padding: 6px 16px;
    gap: 10px;
  }
}
@media (max-width: 390px) {
  #NavMenü2ic {
    width: 85%;
    justify-content: space-evenly;
  }
  .buttonNav {
    padding: 6px 16px;
  }
  .buttonNavCerceve {
    padding: 6px 16px;
    gap: 10px;
  }
}
