#FooterMain {
  width: 100%;
}
.MailFoto {
  width: 26px;
  height: 20px;
  margin-right: 10px;
}
#footeryazi {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 125%;
  padding-bottom: 63px;
  width: 540px;
  padding-top: 85px;
}
#footerAlt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  padding-bottom: 100px;
}
#mainBolumu {
  display: flex;
  text-align: center;
  align-items: center;
}
.MailYazisi {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-decoration-line: underline;
}
#github {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #17d18b;
}

#linkedin {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #0ba6f7;
  padding-left: 20px;
}

@media (max-width: 1042px) {
  #footeryazi {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 450px) {
  #footerAlt {
    justify-content: center;
  }
  #footerLink {
    margin-top: 20px;
  }
  #FooterMain-Yazi {
    margin: 0px 2px;
  }
}
